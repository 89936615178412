import {type Node} from '@onroadvantage/onroadvantage-api';
import {PM, type Circle} from 'leaflet';

import {nodeApi} from '../../../api';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type EditParams} from '../../common/types/EditParams';
import {type SiteDetailsFormValues} from '../components/SiteDetail';

export const useEditSiteDetails = ({
  id: nodeId,
  updateState,
}: EditParams<Node>) => {
  return useSubmitForm<SiteDetailsFormValues>(async (values) => {
    const filteredPlanningSkillIds = values.planningSkills
      ?.filter(
        ({value}) => value !== undefined && value !== null && value !== 0,
      )
      .map(({value}) => value);
    const openTime = values.openTime.toTimeString();
    const closeTime = values.closeTime.toTimeString();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const geofence = (values.geofencesGeoJson as any)?.toGeoJSON();
    let geometry;

    if (geofence.type === 'FeatureCollection') {
      geometry = geofence.features[0].geometry;
    } else {
      geometry = geofence.geometry;
    }
    const multiPolygon = geometry;

    if (geometry.type === 'Point') {
      const circlePolygon = PM.Utils.circleToPolygon(
        values.geofencesGeoJson as Circle,
      ).toGeoJSON().geometry;
      multiPolygon.coordinates = [circlePolygon.coordinates];
      multiPolygon.type = 'MultiPolygon';
    } else if (geometry.type !== 'MultiPolygon') {
      multiPolygon.type = 'MultiPolygon';
      multiPolygon.coordinates = [geometry.coordinates];
    }

    const response = await nodeApi.apiNodeNodeIdPatch({
      nodeId,
      body: {
        name: values.name,
        externalReference: values.externalReference,
        prohibitAfterHoursProcessing: values.prohibitAfterHoursProcessing,
        address: values.address,
        latitude: values.latitude,
        longitude: values.longitude,
        geofencesGeoJson: multiPolygon,
        timeWindows: [
          {
            openTime,
            closeTime,
          },
        ],
        loadingTimeInMinutes: parseFloat(values.loadingTimeInMinutes),
        unloadingTimeInMinutes: parseFloat(values.unloadingTimeInMinutes),
        processingTimeInMinutes: parseFloat(values.processingTimeInMinutes),
        nodeTypeId: values.nodeType.value,
        planningSkillIds: filteredPlanningSkillIds,
      },
    });
    updateState(response);
  });
};
